<template>
    <div>
        <v-layout row pt-6>
            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.contractNumber') }}</v-flex>
            <v-flex xs6 lg4 md6 py-1>
                <div class="data-holder"></div>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.invoiceNumber') }}</v-flex>
            <v-flex xs6 lg4 md6 py-1>
                <div class="data-holder"></div>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.buyer') }}</v-flex>
            <v-flex xs6 lg4 md6 py-1>
                <div class="data-holder"></div>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.buyerSpecificRequests') }}</v-flex>
            <v-flex xs6 lg4 md6 py-1>
                <div class="data-holder"></div>
            </v-flex>
        </v-layout>
        <v-layout row>
            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.supplier') }}</v-flex>
            <v-flex xs6 lg4 md6 py-1>
                <div class="data-holder"></div>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
export default {
    name: "ShipmentGeneralInfo"
}
</script>

<style scoped>
@keyframes flickerAnimation {
    0%   { opacity:1; }
    50%  { opacity:0.4; }
    100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0.4; }
    100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0.4; }
    100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0.4; }
    100% { opacity:1; }
}
.data-holder {
    -webkit-animation: flickerAnimation 3s infinite;
    -moz-animation: flickerAnimation 3s infinite;
    -o-animation: flickerAnimation 3s infinite;
    animation: flickerAnimation 3s infinite;
    height: 32px !important;
    border-radius: 16px;
    background-color: lightgray;
    margin-top: 5px;
}
table td.text-start {
    padding-left: 3px !important;
    padding-right: 0px !important;
}
.v-data-table::v-deep tbody td {
    vertical-align: top !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    height: 40px !important;
}
.v-data-table::v-deep thead th {
    font-size: 0.875rem !important;
    font-weight: bold !important;
    color: black !important;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    height: 21px !important;
}
</style>